import storage from '@/libs/storage'
import Api from '@/api/caseHandle'


const USER_INFO_KEY = 'USER_INFO'
const USER_TOKEN_KEY = 'USER_TOKEN'

export function storeUserInfo(userInfo) {
    storage.setLocal(USER_INFO_KEY, userInfo)
    if (userInfo.tokenInfo) {
        storeToken(userInfo.tokenInfo.access_token)
    }
}

export function getUserInfo() {
    return storage.getLocal(USER_INFO_KEY)
}

export function storeToken(token) {
    storage.setLocal(USER_TOKEN_KEY, token)
}

export function getToken() {
    return storage.getLocal(USER_TOKEN_KEY)
}


export function clearUserInfo() {
    storage.removeLocal(USER_INFO_KEY)
    storage.removeLocal(USER_TOKEN_KEY)
    storage.removeLocal('userAccount')
}


export async function loadRegions() {
    try {
        var res = await Api.regions()
        const { data } = res;
        const province_list = data.filter(item => item.parentKey === '100000')
        const province_code_list = province_list.map(item => item.regionCode);
        const city_list = data.filter(item => province_code_list.includes(item.parentKey))
        const city_code_list = city_list.map(item => item.regionCode);
        const county_list = data.filter(item => city_code_list.includes(item.parentKey))

        const province = {};
        province_list.forEach(item => {
            province[item.regionCode] = item.regionName
        });

        const city = {};
        city_list.forEach(item => {
            city[item.regionCode] = item.regionName
        });

        const county = {};
        county_list.forEach(item => {
            county[item.regionCode] = item.regionName
        });

        return {
            province_list: province,
            city_list: city,
            county_list: county,
        }
    } catch (error) {
        console.warn(error)
    }
    return {}
}

// 根据name导航
export async function goMapName(name, type) {
    // 默认地址
    let longitude = 106.515882;
    let latitude = 29.550471;
    const geocoder = new window.AMap.Geocoder({
        city: '全国',
        batch: true
    });
    geocoder.getLocation(name, (status, result) => {
        if (status === 'complete' && result.info === 'OK') {
            const location = result.geocodes[0].location;
            latitude = location.lat
            longitude = location.lng
        }
        goMap(name, type, longitude, latitude)
    });//获取终点地址经纬度
}
// 根据经纬度导航
export async function goMap(name, type, long = 106.515882, lat = 29.550471) {
    let url
    const u = window.navigator.userAgent
    //判断ios
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    //判断Android
    // const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
    if (isIOS) {
        switch (type) {
            case 1: //百度地图
                url = `http://api.map.baidu.com/marker?location=${lat},${long}&title=${name}&content=${name}&output=html`
                break
            case 2: //高德地图
                url = `http://uri.amap.com/marker?position=${long},${lat}&name=${name}&src=mypage&coordinate=gaode&callnative=0`
                break
            case 3: //腾讯地图
                url = `http://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${long};addr:${name}`
                break;
            default:
                break
        }
    } else {
        switch (type) {
            case 1: //百度地图
                url = `http://api.map.baidu.com/marker?location=${lat},${long}&title=${name}&content=${name}&output=html`
                break
            case 2: //高德地图
                url = `http://uri.amap.com/marker?position=${long},${lat}&name=${name}&src=mypage&coordinate=gaode&callnative=0`
                break
            case 3: //腾讯地图
                url = `http://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${long};addr:${name}`
                break;
            default:
                break
        }
    }
    window.location.href = url;
    //window.open(url);  
}

// 定义一些常量
const pi = 3.14159265358979324;
const a = 6378245.0;
const ee = 0.00669342162296594323;

// 判断是否在中国境内
function outOfChina(lat, lon) {
    if (lon < 72.004 || lon > 137.8347) {
        return true;
    }
    if (lat < 0.8293 || lat > 55.8271) {
        return true;
    }
    return false;
}

// 转换经度
function transformLon(x, y) {
    let ret =
        300.0 +
        x +
        2.0 * y +
        0.1 * x * x +
        0.1 * x * y +
        0.1 * Math.sqrt(Math.abs(x));
    ret +=
        ((20.0 * Math.sin(6.0 * x * pi) +
            20.0 * Math.sin(2.0 * x * pi)) *
            2.0) /
        3.0;
    ret +=
        ((20.0 * Math.sin(x * pi) + 40.0 * Math.sin((x / 3.0) * pi)) * 2.0) / 3.0;
    ret +=
        ((150.0 * Math.sin((x / 12.0) * pi) +
            300.0 * Math.sin((x / 30.0) * pi)) *
            2.0) /
        3.0;
    return ret;
}

// 转换纬度
function transformLat(x, y) {
    let ret =
        -100.0 +
        2.0 * x +
        3.0 * y +
        0.2 * y * y +
        0.1 * x * y +
        0.2 * Math.sqrt(Math.abs(x));
    ret +=
        ((20.0 * Math.sin(6.0 * x * pi) +
            20.0 * Math.sin(2.0 * x * pi)) *
            2.0) /
        3.0;
    ret +=
        ((20.0 * Math.sin(y * pi) + 40.0 * Math.sin((y / 3.0) * pi)) * 2.0) / 3.0;
    ret +=
        ((160.0 * Math.sin((y / 12.0) * pi) +
            320 * Math.sin((y * pi) / 30.0)) *
            2.0) /
        3.0;
    return ret;
}

// WGS84坐标系转GCJ02坐标系
export function wgs84ToGcj02(wgsLat, wgsLon) {
    if (outOfChina(wgsLat, wgsLon)) {
        return [wgsLat, wgsLon];
    }
    let dLat = transformLat(wgsLon - 105.0, wgsLat - 35.0);
    let dLon = transformLon(wgsLon - 105.0, wgsLat - 35.0);
    const radLat = (wgsLat / 180.0) * pi;
    let magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    const sqrtMagic = Math.sqrt(magic);
    dLat = (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * pi);
    dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * pi);
    const gcjLat = wgsLat + dLat;
    const gcjLon = wgsLon + dLon;
    return { latitude: gcjLat, longitude: gcjLon };
}

// GCJ02坐标系转WGS84坐标系
export function gcj02ToWgs84(gcjLat, gcjLon) {
    if (outOfChina(gcjLat, gcjLon)) {
        return [gcjLat, gcjLon];
    }
    let dLat = transformLat(gcjLon - 105.0, gcjLat - 35.0);
    let dLon = transformLon(gcjLon - 105.0, gcjLat - 35.0);
    const radLat = (gcjLat / 180.0) * pi;
    let magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    const sqrtMagic = Math.sqrt(magic);
    dLat = (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * pi);
    dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * pi);
    const wgsLat = gcjLat - dLat;
    const wgsLon = gcjLon - dLon;
    return { latitude: wgsLat, longitude: wgsLon };
}
