// 家访列表
export default [
	{
		path: "/list",
		name: "List",
		component: () => import("@/views/List/List.vue"),
		meta: {
			title: "家访列表"
		}
	},
	{
		path: "/list/feedback",
		name: "ListFeedBack",
		component: () => import("@/views/List/ListFeedBack.vue"),
		meta: {
			title: "待反馈案件"
		}
	},
	{
		path: "/list/expire",
		name: "ListExpire",
		component: () => import("@/views/List/ListExpire.vue"),
		meta: {
			title: "即将到期"
		}
	},
	{
		path: "/detail/:homeNo",
		name: "Detail",
		component: () => import("@/views/Detail/Detail.vue"),
		meta: {
			title: "家访详情"
		}
	},
	{
		path: "/additional/:homeNo",
		name: "Additional",
		component: () => import("@/views/Additional/Additional.vue"),
		meta: {
			title: "补充信息"
		}
	},
	{
		path: "/list/case",
		name: "ListCase",
		component: () => import("@/views/List/ListCase.vue"),
		meta: {
			title: "案件列表"
		}
	},
	{
		path: "/caseDetail/:homeNo",
		name: "CaseDetail",
		component: () => import("@/views/CaseDetail/CaseDetail.vue"),
		meta: {
			title: "案件详情"
		}
	},
	{
		path: "/gpsList",
		name: "GpsList",
		component: () => import("@/views/CaseDetail/GpsList.vue"),
		meta: {
			title: "GPS信息"
		}
	},
	{
		path: "/searchCase",
		name: "searchCase",
		component: () => import("@/views/SearchCase/SearchCase.vue"),
		meta: {
			title: "查询案件"
		}
	},
	{
		path: "/visitRecords",
		name: "VisitRecords",
		component: () => import("@/views/CaseDetail/VisitRecords.vue"),
		meta: {
			title: "历史记录"
		}
	},
	{
		path: "/settle",
		name: "Settle",
		component: () => import("@/views/Settle/Settle.vue"),
		meta: {
			title: "家访反馈"
		}
	},
	{
		path: "/settle2",
		name: "Settle2",
		component: () => import("@/views/Settle/Settle2.vue"),
		meta: {
			title: "家访反馈"
		}
	},
	{
		path: "/file-viewer",
		name: "FileViewer",
		component: () => import("@/views/FileViewer/viewer.vue"),
		meta: {
			title: "查看PDF"
		}
	},
	{
		path: "/mine/salesman-list",
		name: "salesmanList",
		component: () => import("@/views/ManageSalesman/SalesmanList.vue"),
		meta: {
			title: "业务员列表"
		}
	},
	{
		path: "/mine/salesman-list/create",
		name: "createSalesman",
		component: () => import("@/views/ManageSalesman/CreateSalesman.vue"),
		meta: {
			title: "新增业务员"
		}
	},
	{
		path: "/search",
		name: "search",
		component: () => import("@/views/Search/Search.vue"),
		meta: {
			title: "查询"
		}
	},
	{
		path: "/delay/request",
		name: "DelayRequest",
		component: () => import("@/views/Delay/Request.vue"),
		meta: {
			title: "续期申请"
		}
	},

	{
		path: "/feedback/request",
		name: "FeedBackRequest",
		component: () => import("@/views/FeedBack/Request.vue"),
		meta: {
			title: "反馈"
		}
	},
	{
		path: "/feedback/record",
		name: "FeedBackRecord",
		component: () => import("@/views/FeedBack/Record.vue"),
		meta: {
			title: "反馈记录"
		}
	},
	{
		path: "/map",
		name: "Map",
		component: () => import("@/views/Map/index.vue"),
		meta: {
			title: "map"
		}
	},
	{
		path: "/calendar",
		name: "Calendar",
		component: () => import("@/views/Map/calendar.vue"),
		meta: {
			title: "calendar"
		}
	}
];
