<template>
  <div class="case">
    <div class="top">
      <div class="l">
        {{ item.applyBizNo }} - {{ item.customerName | dash }}
      </div>
      <!-- <div class="r danger" v-if="item.currentOverdueDays > 0">
        逾期天数：{{ item.currentOverdueDays }} 天
      </div> -->
      <!-- <div class="r">
        客户：{{ item.customerName | dash }}
      </div> -->
    </div>
    <div class="content">
      <div class="attrs">
        <div>家访区域：{{ item.homeVisitArea }}</div>
        <div>建议时间段：{{ item.homeVisitTimeInterval | dash }}</div>
        <div>备注：{{ item.dispatchedOpinion | dash }}</div>
        <div v-if="'ROLLBACK' == item.latestResults && item.latestReason">
          驳回原因：{{ item.latestReason | dash }}
        </div>
        <div>家访时间：{{ item.homeVisitDate }}</div>
        <!-- <div>剩余时间：{{ item.expireTime }}</div> -->
        <div
          v-if="
            ['APPROVALING', 'PROCESSING'].includes(item.status) &&
              item.expireTime
          "
        >
          剩余时间：<span class="danger">{{ calDay(item.expireTime) }}天</span>
        </div>
      </div>
      <div class="status">
        <span :class="{PROCESSING: 'warning',APPROVALING: 'warning',FINISHED: 'success',OVERDUE_CANCEL: 'danger',CANCELED: 'danger'}[item.status]"
        >
          {{ item.status | enumMessage(getHomeStatusEnum) }}</span
        >
      </div>
      <div v-if="item.iouStatus == 'CLEAR'" class="tag">已结清</div>
      <div
        v-if="item.status == 'PROCESSING' && item.latestResults == 'ROLLBACK'"
        class="tag warning"
      >
        驳回
      </div>
    </div>
    <div class="line"></div>
    <div class="footer">
      <div class="tips">
        <span
          class="danger"
          v-if="
            item.unFeedbackDay > 0 &&
              !['FINISHED', 'OVERDUE_CANCEL'].includes(item.status)
          "
          ><i class="iconfont icon-infofill"></i
          >{{ item.unFeedbackDay }}天未反馈</span
        >
      </div>
      <div class="buttons">
        <button
          v-if="['PROCESSING'].includes(item.status) && isShowBtn"
          class="expire"
          @click.stop="onActionClick('中止', item)"
        >
          中止
        </button>
        <button
          v-if="'PROCESSING' == item.status && isShowBtn"
          class="expire"
          @click.stop="onActionClick('改期', item)"
        >
          改期
        </button>
        <button
          v-if="applicationForRenewal"
          class="expire"
          @click.stop="onActionClick('DelayRequest', item)"
        >
          续期申请
        </button>
        <button
          v-if="
            ['APPROVALING', 'PROCESSING'].includes(item.status) && isShowBtn
          "
          class="feedback"
          @click.stop="onActionClick('FeedBackRequest', item)"
        >
          反馈
        </button>
        <!-- <button
          class="close"
          v-if="['PROCESSING'].includes(item.status)"
          @click.stop="onActionClick('Settle', item)"
        >
          结案
        </button> -->
        <button
          v-if="'PROCESSING' == item.status && isShowBtn"
          class="close"
          @click.stop="onActionClick('家访', item)"
        >
          家访
        </button>
        <button
          v-if="'PROCESSING' == item.status && isAdmin && !isShowBtn"
          class="close"
          @click.stop="goDetail(item)"
        >
          详情
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    // 管理员岗位：可见，但不能操作
    isAdmin: {
      type: Boolean,
      default: () => false,
    },
    userInfo: {
      type: Object,
      default: () => {},
    },
    isExpire: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    isShowBtn() {
      if (
        this.item.candidate &&
        this.item.candidate.includes(this.userInfo.operatorId)
      ) {
        return true;
      }
      return false;
    },
    applicationForRenewal() {
      if (this.isExpire) {
        return this.isAdmin || ["PROCESSING"].includes(this.item.status) && this.isShowBtn;
      } else {
        return ["PROCESSING"].includes(this.item.status) && this.isShowBtn;
      }
    },
  },
  methods: {
    calDay(dateStr) {
      if (!dateStr) {
        return "-";
      }
      const date1 = dayjs(dayjs().format("YYYY-MM-DD"));
      const date2 = dayjs(dayjs(dateStr).format("YYYY-MM-DD"));
      // 计算两个日期之间的天数
      const daysDiff = date2.diff(date1, "day");
      return daysDiff;
    },
    onActionClick(routeName, item) {
      if (["改期", "中止"].includes(routeName)) {
        this.$emit("actionClick", routeName, item);
        return;
      }
      if (routeName == "家访") {
        this.$router.push({
          name: "CaseDetail",
          params: {
            homeNo: item.homeNo,
          },
          query: { bizNo: item.applyBizNo },
        });
        return;
      }
      this.$router.push({
        name: routeName,
        query: {
          projectCode: item.applyBizNo,
          homeNo: item.homeNo,
          assignNo: item.assignNo,
          orgName: item.orgName,
          homeStatus: item.status,
        },
      });
    },

    // 详情
    goDetail(item) {
      this.$router.push({
        name: "CaseDetail",
        params: {
          homeNo: item.homeNo,
        },
        query: { bizNo: item.applyBizNo, readonly: true },
      });
      // 只有处理中 & 待审核可查看详情
      // if (!["PROCESSING", "APPROVALING"].includes(item.status)) {
      //   return;
      // }
      // this.$router.push({
      //   name: "Detail",
      //   params: {
      //     homeNo: item.homeNo,
      //   },
      // });
    },
  },
  mounted() {
    this.fetchEnums("getHomeStatusEnum");
  },
};
</script>
<style lang="less" scoped>
.success {
  color: #07c160;
}

.danger {
  color: #ff4848;
}

.warning {
  color: #fa9540;
}

.case {
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  user-select: text;

  &::before {
    content: " ";
    position: absolute;
    left: 0px;
    top: 12px;
    width: 4px;
    height: 32px;
    background: #3c86ff;
    border-radius: 0 100px 100px 0px;
  }

  .top {
    padding: 18px 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    letter-spacing: 0;
    color: #2b2e32;

    .l {
      font-weight: bold;
    }

    .r {
      font-weight: 500;
    }
  }

  .content {
    position: relative;
    display: flex;

    .attrs {
      flex: 1;
      padding: 0px 16px 18px 17px;

      div {
        color: #787b84;
        font-size: 12px;
        line-height: 1.8em;
      }
    }

    .status {
      font-size: 12px;
      letter-spacing: 0;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0px 20px 0px 0px;
    }

    .tag {
      position: absolute;
      right: 0px;
      bottom: 10px;
      padding: 8px 10px 8px 16px;
      background: #7bd17c;
      border-radius: 100px 0 0 100px;
      font-size: 12px;
      color: #ffffff;
      letter-spacing: 0;

      &.warning {
        background: #fa9540;
      }
    }
  }

  .line {
    height: 1px;
    background: #f1f2f7;
  }

  .footer {
    display: flex;
    padding: 0 9px;
    justify-content: space-between;
    height: 47px;
    line-height: 47px;
    font-size: 12px;

    .tips {
      .iconfont {
        margin-right: 5px;
        font-size: 13px;
      }
    }

    .buttons {
      button {
        font-size: 12px;
        letter-spacing: 0;
        font-weight: 500px;
        border: 1px solid currentColor;
        border-radius: 2px;
        padding: 7px 5px;
        background: transparent;
        box-sizing: border-box;
        + button {
          margin-left: 9px;
        }

        &.expire {
          color: #ff9f00;
        }

        &.feedback {
          color: #4cb84c;
        }

        &.close {
          background: #3c86ff;
          color: #fff;
          border-color: #3c86ff;
        }
      }
    }
  }
}
</style>
